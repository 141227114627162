<template>
  <div>
    <div class="search-area">
      <div class="title">帮我下单</div>
      <div class="row">
        <div class="name">需求编号</div>
        <div class="input"><el-input v-model="demandNo"></el-input></div>
        <!-- <div class='name mar-left'>收货人</div>
              <div class='input'><el-input></el-input></div> -->
        <div class="name mar-left">发布时间</div>
        <div class="input">
          <el-date-picker
            v-model="beginTime"
            type="datetime"
            value-format="yyyy-MM-dd HH:mm:ss"
            placeholder="选择日期时间"
          >
          </el-date-picker>
        </div>
        <div class="space">至</div>
        <div class="input">
          <el-date-picker
            v-model="endTime"
            type="datetime"
            value-format="yyyy-MM-dd HH:mm:ss"
            placeholder="选择日期时间"
          >
          </el-date-picker>
        </div>
        <div class="search-button" @click="searchButton">
          <i class="el-icon-search"></i>搜索
        </div>
      </div>
    </div>
    <div class="order-class-list">
      <div
        @click="status = item.id"
        :class="['order-class-item', { active: status === item.id }]"
        v-for="(item, index) in stateList"
        :key="index"
      >
        {{ item.desc }}
      </div>
    </div>
    <div
      v-for="(item, index) in orderList"
      :key="index"
      style="margin-bottom: 30px; background-color: white; padding: 20px"
    >
      <el-row>
        <el-col :span="6"
          ><div>需求编号:{{ item.demandNo }}</div></el-col
        >
        <el-col :span="8"
          ><div>下单人:{{ item.userName }}</div></el-col
        >
        <el-col :span="8"><div></div></el-col>
      </el-row>
      <el-row>
        <el-col :span="6"
          ><div>下单时间:{{ item.createTime }}</div></el-col
        >
        <el-col :span="8"
          ><div>联系电话:{{ item.contactsPersonPhone }}</div></el-col
        >
        <el-col :span="8"><div></div></el-col>
        <!-- {{orderDetailLog}} -->
      </el-row>
      <el-table
        :data="[item]"
        style="width: 100%"
        :header-cell-style="{
          'background-color': '#fafafa',
        }"
      >
        <el-table-column prop="productName" label="货品名称" width="200">
        </el-table-column>
        <el-table-column prop="designDocuments" label="设计文件" width="200">
          <template slot-scope="scope">
            <el-button
              v-if="scope.row.designDocuments"
              size="mini"
              type="warning"
              @click="downLoadFile(scope.row.designDocuments)"
              >文件下载</el-button
            >
            <span v-else>未上传</span>
            <!-- {{scope.row.designDocuments?scope.row.designDocuments:'未上传'}} -->
          </template>
        </el-table-column>
        <el-table-column prop="count" label="数量" width="100">
        </el-table-column>
        <el-table-column prop="describe" label="需求描述" width="100">
          <template slot-scope="scope">
            <div @click="getDetailMessage(scope.row.describe)" class="ellipsis">
              {{ scope.row.describe }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="需求状态" width="100">
          <template slot-scope="scope">
            {{ scope.row.orderStatus | orderStatusDesc }}
          </template>
        </el-table-column>
        <el-table-column prop="remark" label="备注" width="200">
        </el-table-column>
        <el-table-column prop="date" label="详情" width="100">
          <template slot-scope="scope">
            <!-- <el-button v-if="scope.row.status==1||scope.row.status==3" size="mini" type="warning" @click="checkDetail(scope.row)">详情</el-button> -->
            <el-button
              v-if="scope.row.orderStatus == 1"
              size="mini"
              type="warning"
              @click="checkDetail(scope.row)"
              >详情</el-button
            >
          </template>
        </el-table-column>
        <el-table-column
          prop="orderSerialCode"
          label="关联的订单号"
          width="200"
        >
          <template slot-scope="scope" v-if="scope.row.orderSerialCode">
            <span @click="showProductDetail(scope.row)" class="orderSerialCode">
              {{ scope.row.orderSerialCode }}</span
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div align="right">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <el-dialog title="订单详情" :visible.sync="orderDetailLog">
      <el-table :data="[detail]">
        <el-table-column label="货品规格" width="150">
          <template slot-scope="scope">
            <div>{{ scope.row.productName }}</div>
            {{ scope.row.skuCode | skuCodeInfo }}
          </template>
        </el-table-column>
        <el-table-column property="designDocuments" label="文件" width="150">
          <template slot-scope="scope">
            <el-button
              v-if="scope.row.designFile"
              size="mini"
              type="warning"
              @click="downLoadFile(scope.row.designFile)"
              >文件下载</el-button
            >
            <span v-else>未上传</span>
            <!-- {{scope.row.designDocuments?scope.row.designDocuments:'未上传'}} -->
          </template>
        </el-table-column>
        <el-table-column
          property="amount"
          label="货品金额"
          width="150"
        ></el-table-column>
        <el-table-column
          property="quantity"
          label="数量"
          width="150"
        ></el-table-column>
        <el-table-column property="amount" label="订单金额" width="150">
          <template slot-scope="scope">
            {{ scope.row.amount + scope.row.freight }}
          </template>
        </el-table-column>
        <el-table-column property="" label="操作">
          <template slot-scope>
            <div class="scope-button" v-if="detail.orderStatus == 1">
              <el-button size="mini" type="warning" @click="toPay(detail)"
                >确认</el-button
              >
            </div>
            <div class="scope-button" v-if="detail.orderStatus == 1">
              <!-- @click="modifyStatus(detail, 4)" -->
              <el-button size="mini" type="warning" @click="cancel(detail)"
                >取消</el-button
              >
            </div>
            <div class="scope-button">
              <el-button
                size="mini"
                type="warning"
                @click="orderDetailLog = false"
                >返回</el-button
              >
            </div>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>

    <el-dialog title="订单详情" :visible.sync="productDetailLog">
      <el-table
        :data="[productDetail]"
        style="width: 100%"
        :header-cell-style="{
          'background-color': '#fafafa',
        }"
      >
        <el-table-column label="货品规格" width="200">
          <template slot-scope="scope">
            {{ scope.row.skuCode | skuCodeInfo }}
          </template>
        </el-table-column>

        <el-table-column prop="designDocuments" label="文件" width="200">
        </el-table-column>
        <el-table-column prop="price" label="货品金额" width="100">
        </el-table-column>
        <el-table-column prop="purchaseCount" label="数量" width="100">
        </el-table-column>
        <el-table-column
          prop="orderItemPayableAmount"
          label="订单金额"
          width="200"
        >
        </el-table-column>
        <el-table-column label="发票状态" width="200">
          <template slot-scope="scope">
            {{ scope.row.invoiceStatus | invoiceStatusDesc }}
          </template>
        </el-table-column>
        <el-table-column label="订单状态" width="200">
          <template>{{ productDetail.orderStatus | orderStatusDesc }}</template>
        </el-table-column>
        <el-table-column prop="" label="其他" width="100">
          <template slot-scope>
            <div class="scope-div">
              <el-button
                size="mini"
                type="warning"
                @click="checkdetailInfo(productDetail)"
                >订单详情</el-button
              >
            </div>
            <div class="scope-div" v-if="productDetail.orderStatus == '1'">
              <el-button
                size="mini"
                type="warning"
                @click="toPay(productDetail)"
                >去付款</el-button
              >
            </div>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
    <el-dialog title="订单详情" :visible.sync="showOrderDetail"> </el-dialog>
    <el-dialog title="详情" :visible.sync="detailInfo" width="30%">
      <span>{{ detailMessage }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="detailInfo = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>

import { pageListByPersonalUser, searchOrderDetail, queryBookingInfoFromCartById, personalUserCancelOrder } from '@/api/printonline'
import { getRoleIds, downLoadFile } from '@/lib/util'
import { orderdemandcancel, orderdemandqueryDetail, orderdemandqueryPage } from '@/api/cubeclient'

export default {
  data () {
    return {
      orderList: [
      ],
      demandNo: '',
      beginTime: '',
      endTime: '',
      detail: {},
      orderDetailLog: false,
      state: {},
      productDetailLog: false,
      productDetail: {},
      orderDetail: {},
      showOrderDetail: false,
      stateList: [
        { id: '', desc: '全部' },
        { id: 0, desc: '待完善' },
        // {id:1,desc:'已完善'},
        { id: 1, desc: '待确认' },
        { id: 3, desc: '已确认' },
        { id: 4, desc: '已取消' },
        { id: 5, desc: '已拒绝' }
      ],
      status: '',
      detailInfo: false,
      detailMessage: '',
      total: 0,
      pageSize: 10,
      page: 0

    }
  },
  components: {
  },
  mounted () {
    this.init()
  },
  methods: {
    handleCurrentChange (val) {
      this.page = val
      this.search()
    },
    handleSizeChange (val) {
      this.pageSize = val
      this.page = 1
      this.search()
    },
    downLoadFile (url) {
      const type = url.split('.').pop()
      downLoadFile(url, type)
    },
    getDetailMessage (info) {
      this.detailInfo = true
      this.detailMessage = info
    },
    toPay (detail) {
      this.$router.push({ name: 'OrderItemList', params: { productInfos: [detail], DemandNo: detail.demandNo } })
    },
    async init () {
      this.search()
    },
    showProductDetail (item) {
      this.$router.push({ name: 'PrintOrder', query: { orderSerialCode: item.orderSerialCode } })
    },

    async checkdetailInfo (item) {
      const res = await orderdemandqueryDetail({ "demandNo": item.orderCode })
      this.orderDetail = res.data
      this.showOrderDetail = true
    },
    searchButton () {
      this.page = 1
      this.search()
    },
    async search () {
      const param = {
        createTimeBegin: this.beginTime,
        createTimeEnd: this.endTime,
        demandNo: this.demandNo,
        pageNo: this.page,
        pageSize: this.pageSize
      }
      const res = await orderdemandqueryPage(param)
      this.orderList = res.data.list
      this.total = res.data.total
    },
    async checkDetail (order) {
      const res = await orderdemandqueryDetail({
        demandNo: order.demandNo
      })
      this.detail = { ...res.data, ...order }
      this.orderDetailLog = true
    },

    async cancel (obj) {
      await orderdemandcancel({ demandNo: obj.demandNo })
      this.orderDetailLog = false
      this.search()
    }
  },

  filters: {
    invoiceStatusDesc (index) {
      // 0 未申请开票 ，1 待开票 ， 2 已开票
      switch (index) {
        case '1':
          return '待开票'
        case '2':
          return '已开票'
        default:
          return '未申请开票'
      }
    },
    skuCodeInfo (value) {
      if (!value) {
        return ''
      }
      let result = ''
      const skuCodes = JSON.parse(value)
      skuCodes.forEach(skuCode => {
        for (const key in skuCode) {
          result = result + `${key}:${skuCode[key]}  `
        }
      })
      return result
    },
    //    0 待完善，1 已完善，2 待确认，3 已确认，4 已取消 ，5 已拒绝
    orderStatusDesc (index) {
      //  //  0 待完善，1 已完善，2 待确认，3 已确认，4 已取消 ，5 已拒绝
      switch (String(index)) {
        case '0':
          return '待完善'
        case '1':
          return '待确认'
        case '3':
          return '已确认'
        case '4':
          return '已取消'
        case '5':
          return '已拒绝'
        default:
          return ''
      }
    },
    stateName (value) {
      switch (value) {
        case 'all':
          return '全部'
        case 'toBeImproved':
          return '待完善'
        case 'improved':
          return '已完善'
        case 'toBeConfirmed':
          return '待确认'
        case 'confirmed':
          return '已确认'
        case 'cancelled':
          return '已取消'
        case 'rejected':
          return '已拒绝'
        default:
          return ''
      }
    }
  },
  watch: {
    status (newV, oldV) {
      this.search()
    }
  }

}
</script>

<style lang="scss" scoped>
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 8;
  -webkit-box-orient: vertical;
  cursor: pointer;
}
.orderSerialCode {
  cursor: pointer;
  color: rgb(0, 174, 255);
}

.scope-button {
  margin-top: 10px;
}
.search-area {
  width: 100%;
  height: 150px;
  background: #ffffff;
  padding-left: 30px;
  .title {
    padding-top: 20px;
    font-size: 26px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #191c3d;
    margin-bottom: 30px;
  }
  .row {
    width: 100%;
    margin-bottom: 20px;
    display: inline-block;
    position: relative;
    .name {
      padding-right: 10px;
      float: left;
      font-size: 14px;
      line-height: 40px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #191c3d;
    }
    .input {
      float: left;
      width: 200px;
    }
    .space {
      float: left;
      line-height: 40px;
      font-size: 12px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #000000;
      width: 80px;
      margin-left: 20px;
      text-align: center;
      padding: 0 10px 0 10px;
    }
    .mar-left {
      margin-left: 55px;
    }
    .select {
      float: left;
      width: 120px;
    }
    .search-button {
      position: absolute;
      right: 50px;
      width: 100px;
      height: 40px;
      background: #2468f2;
      border-radius: 4px;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }
}
.order-class-list {
  margin-top: 16px;
  width: 100%;
  height: 56px;
  background: #dadada;
  .active {
    background: #ffffff;
  }
  .order-class-item {
    float: left;
    width: 120px;
    height: 56px;

    text-align: center;
    line-height: 56px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #191c3d;
    cursor: pointer;
  }
}

.order-item {
  width: 100%;
  background: #ffffff;
  margin-top: 20px;
  padding: 20px;
}
</style>
